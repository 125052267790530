.main-slogan {
  text-align: center;
  font-family: "Oswald", serif;
  font-weight: 900;
  transform: translateY(45%);
  transition: transform 500ms ease;

  body.first-spin-complete & {
    transform: translateY(0);
  }

  .display-medium {
    margin: 0;
    color: #00B147;
  }

  .display-small {
    margin: 0;
    color: #FFFFFF;
  }
}
