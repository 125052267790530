@use "base/mixins" as *;

@include max(tablet-landscape) {
  .character-elements {
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    inset: auto 0 0;
    margin: auto;
    display: flex;
    justify-content: center;
    width: calc(100% + 260px);
    transform: translate(-130px, 10vh);

    .image-container {

    }

    .image-container--character-one {

    }

    .image-container--character-two {

    }
  }
}

@include min(tablet-landscape) {
  .character-elements {
    pointer-events: none;
    position: absolute;
    inset: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    width: min(96%, 1800px);
    height: fit-content;
    transform: translateY(8vh);

    .image-container {
      width: fit-content;
      filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.35));
    }

    .image-container--character-one {
      transform: translateX(-160px);
    }

    .image-container--character-two {
      transform: translateX(160px);
    }
  }
}
