:root {
  --max-vw: 1440;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .75;
  --min-fs-rem: calc(var(--min-fs) * 1rem);
  --max-fs-rem: calc(var(--max-fs) * 1rem);
  --min-vw-rem: calc((var(--min-vw) / 10) * 1rem);
  --slope: calc((var(--max-fs)  - var(--min-fs)) * (100vw - var(--min-vw-rem)) / ((var(--max-vw) / 10)  - (var(--min-vw) / 10)));
  --font-size-clamp: clamp(var(--min-fs-rem), calc(var(--min-fs-rem)  + var(--slope)), var(--max-fs-rem));
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;
}

html {
  font-feature-settings: "kern" 1, "liga" 0;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 10px;
}

body {
  font-family: var(--font-family-base, "Inter");
  font-size: var(--font-size-clamp);
  line-height: var(--line-height-base);
  font-weight: 400;
}

a {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

.display-large {
  font-size: 5.7em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.08em;
}

.display-medium {
  font-size: 4.5em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.display-small {
  font-size: 3.6em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.headline-large {
  font-size: 3.2em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-medium {
  font-size: 2.8em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-small {
  font-size: 2.4em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.05em;
}

.title-large {
  font-size: 2.2em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.04em;
}

.title-medium {
  font-size: 1.6rem;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.03em;
  font-weight: 600;
}

.title-small {
  font-size: 1.4rem;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  font-weight: 600;
}

.label-large {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
}

.label-medium {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.label-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.body-large {
  text-wrap: balance;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.body-medium {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

.body-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

ul, ol {
  padding-left: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  list-style-position: outside;
}

.dialog-lite {
  z-index: var(--z-index-dialog-lite, 992);
  width: 100vw;
  position: fixed;
  inset: 0;
  overflow: clip auto;
}

.dialog-lite--in {
  -webkit-overflow-scrolling: touch;
}

.dialog-lite--out {
  visibility: hidden;
  pointer-events: none;
}

.dialog-lite__backdrop {
  z-index: var(--z-index-dialog-lite-backdrop, 993);
  margin: auto;
  position: fixed;
  inset: 0;
}

.dialog-lite--in .dialog-lite__backdrop {
  background-color: #0c0c13d1;
  transition: background-color .4s cubic-bezier(.61, 1, .88, 1);
}

.dialog-lite--out .dialog-lite__backdrop {
  pointer-events: none;
  background-color: #0f0f1000;
  transition: background-color .5s cubic-bezier(0, 0, .5, 1);
}

.dialog-lite__container {
  pointer-events: none;
  z-index: var(--z-index-dialog-lite-container, 994);
  place-content: center;
  width: 100vw;
  display: grid;
  position: relative;
}

@supports (min-height: 100dvh) {
  .dialog-lite__container {
    min-height: 100dvh;
  }
}

@supports not (min-height: 100dvh) {
  .dialog-lite__container {
    min-height: 100vh;
  }
}

.dialog-lite__container-inner {
  margin: 20px;
  position: relative;
}

.dialog-lite--in .dialog-lite__container-inner {
  pointer-events: auto;
  opacity: 1;
  transition: opacity .4s cubic-bezier(.61, 1, .88, 1), transform .4s cubic-bezier(.61, 1, .88, 1);
  transform: translateY(0);
}

.dialog-lite--out .dialog-lite__container-inner {
  pointer-events: none;
  opacity: 0;
  transition: opacity .5s cubic-bezier(0, 0, .5, 1), transform .55s cubic-bezier(.22, 1, .5, .95);
  transform: translateY(40px);
}

.dialog-lite-close-button {
  cursor: pointer;
  place-content: center;
  width: 50px;
  height: 50px;
  display: grid;
  position: absolute;
  inset: 0 0 auto auto;
}

.dialog-lite-close-button .svg-icon {
  fill: #000;
  width: 24px;
  height: 24px;
}

:where(:not(html, iframe, canvas, img, svg, video, audio, svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

a, button {
  cursor: revert;
}

ol, ul, menu, summary {
  list-style: none;
}

ol {
  counter-reset: revert;
}

img {
  max-block-size: 100%;
  max-inline-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
  user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

:where(pre) {
  all: revert;
  box-sizing: border-box;
}

::placeholder {
  color: unset;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
  user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
  box-sizing: border-box;
}

::-webkit-details-marker {
  display: none;
}

:root {
  --c-white: #fff;
  --max-vw: 1600;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .6;
  --font-family-base: "Montserrat", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;
  --v-offset-lt: min(20px, 1.2vmax);
  --v-offset-sm: min(40px, 2.4vmax);
  --v-offset-md: min(80px, 4.8vmax);
  --v-offset-lg: min(160px, 9.6vmax);
}

.display-large {
  color: #a9a9a9;
  margin: 20px 0 0;
  font-weight: 900;
}

html, body {
  position: relative;
  overflow-x: hidden;
}

body {
  background-color: #010010;
  max-width: 100vw;
}

@supports (min-height: 100dvh) {
  body {
    min-height: 100dvh;
  }
}

@supports not (min-height: 100dvh) {
  body {
    min-height: 100vh;
  }
}

@media (width <= 1199.98px) {
  [data-device="max-tablet-landscape"] {
    display: none;
  }
}

@media (width >= 1200px) {
  [data-device="min-tablet-landscape"] {
    display: none;
  }
}

@media (width <= 991.98px) {
  [data-device="max-tablet-portrait"] {
    display: none;
  }
}

@media (width >= 992px) {
  [data-device="min-tablet-portrait"] {
    display: none;
  }
}

@media (width <= 767.98px) {
  [data-device="max-phone-landscape"] {
    display: none;
  }
}

@media (width >= 768px) {
  [data-device="min-phone-landscape"] {
    display: none;
  }
}

@media (width <= 575.98px) {
  [data-device="max-phone-portrait"] {
    display: none;
  }
}

@media (width >= 576px) {
  [data-device="min-phone-portrait"] {
    display: none;
  }
}

@media (width <= 1200px) and (any-pointer: coarse) {
  [data-device="max-touch"] {
    display: none;
  }
}

@media (width >= 1200px) and (any-pointer: fine) {
  [data-device="min-touch"] {
    display: none;
  }
}

.page {
  gap: var(--v-offset-lt) 0;
  padding: var(--v-offset-lt) 0 var(--v-offset-lt);
  display: grid;
}

@supports (min-height: 100dvh) {
  .page {
    min-height: 100dvh;
  }
}

@supports not (min-height: 100dvh) {
  .page {
    min-height: 100vh;
  }
}

.page__logotype, .page__gain, .page__wheel {
  place-self: center;
}

@media (width <= 1199.98px) {
  .page__wheel {
    position: relative;
  }
}

.page__payment {
  place-self: center;
  width: 100%;
}

.svg-icon--logo-crasher {
  aspect-ratio: 300 / 68;
  width: clamp(160px, 22vmin, 300px);
}

.image-container {
  width: 100%;
  height: 100%;
}

.image-container picture {
  width: 100%;
  height: 100%;
  display: flex;
}

.image-container img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.image-container--gain-message-one img, .image-container--gain-message-two img {
  object-fit: contain;
}

.image-container--spin-wheel, .image-container--spin-again {
  margin: auto;
  transition: opacity .725s ease-in-out;
  position: absolute;
  inset: 0;
}

.image-container--page-background {
  z-index: -1;
  width: min(100vw, 2560px);
  height: 100vh;
  margin: auto;
  position: fixed;
  inset: 0;
}

.image-container--character-one img {
  object-position: left center;
  object-fit: contain;
}

.image-container--character-two img {
  object-position: right center;
  object-fit: contain;
}

.image-container--bonus-banner img {
  object-position: left center;
  object-fit: cover;
  border-radius: 7.5px;
  overflow: hidden;
}

.wheel-fortune {
  --wheel-total-size: clamp(340px, 92vmin, 540px);
  --wheel-arrow-size: 20%;
  --wheel-arrow-translate: -7%;
  --wheel-decrease-backing: 10px;
  --wheel-decrease-segments: 9.5%;
  --wheel-decrease-blackout: 9.5%;
  --wheel-decrease-blurring: 9.5%;
  --wheel-decrease-frame: 0;
  --wheel-decrease-arrow: 0 0 auto;
  --wheel-decrease-button: 35%;
  --wheel-blackout-color: #0a0a0a;
  --wheel-backing-color: #e6e6e6;
  width: var(--wheel-total-size);
  height: var(--wheel-total-size);
  position: relative;
}

.wheel-fortune__backing {
  inset: var(--wheel-decrease-backing);
  background-color: var(--wheel-backing-color);
  border-radius: 100%;
  margin: auto;
  position: absolute;
}

.wheel-fortune__segments {
  inset: var(--wheel-decrease-segments);
  will-change: transform;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  overflow: hidden;
}

.wheel-fortune__blackout {
  --cutout: calc(100% - (100% / var(--blackout-angle, 8)));
  --color: var(--wheel-blackout-color);
  inset: var(--wheel-decrease-blackout);
  opacity: var(--blackout-opacity, 0);
  background: conic-gradient(from 0deg, var(--color) var(--cutout), transparent var(--cutout));
  transform: rotate(calc(360deg / var(--blackout-angle, 8) / 2));
  border-radius: 100%;
  margin: auto;
  position: absolute;
}

.wheel-fortune__blurring {
  inset: var(--wheel-decrease-blurring);
  backdrop-filter: blur(var(--blurring));
  backface-visibility: hidden;
  border-radius: 100%;
  margin: auto;
  position: absolute;
}

.wheel-fortune__frame {
  inset: var(--wheel-decrease-frame);
  margin: auto;
  position: absolute;
}

.wheel-fortune__arrow {
  inset: var(--wheel-decrease-arrow);
  width: var(--wheel-arrow-size);
  height: var(--wheel-arrow-size);
  transform: translateY(var(--wheel-arrow-translate));
  margin: auto;
  position: absolute;
}

.wheel-fortune__button {
  inset: var(--wheel-decrease-button);
  --opacity-spin-wheel: 1;
  --opacity-spin-again: 0;
  cursor: pointer;
  margin: auto;
  transition: filter .425s ease-in-out;
  position: absolute;
}

.wheel-fortune__button .image-container--spin-wheel {
  opacity: var(--opacity-spin-wheel);
}

.wheel-fortune__button .image-container--spin-again {
  opacity: var(--opacity-spin-again);
}

.is-spinning .wheel-fortune__button, .end-last-spin .wheel-fortune__button {
  pointer-events: none;
  filter: grayscale(.4) brightness(.4);
}

body.first-spin-complete .wheel-fortune__button {
  --opacity-spin-wheel: 0;
  --opacity-spin-again: 1;
}

.wheel-fortune__button:after {
  pointer-events: none;
  content: "";
  z-index: 1;
  border-radius: 100%;
  margin: 0;
  animation: 1s infinite alternate glow-color-change;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 2em red, 0 0 2em red;
}

@keyframes glow-color-change {
  0% {
    box-shadow: 0 0 2em red, 0 0 2em red;
  }

  100% {
    box-shadow: 0 0 1.5em #ff0, 0 0 1.5em #ff0;
  }
}

.payment-brands {
  grid-template-columns: repeat(5, auto);
  gap: 0 20px;
  width: min(400px, 100% - 30px);
  margin: auto;
  display: grid;
}

.payment-brands__item {
  place-content: center;
  display: grid;
}

.payment-brands .svg-icon--payment-brands {
  aspect-ratio: 100 / 54;
  width: 100%;
  fill: var(--c-white);
}

.gain-message {
  width: min(90vw, 660px);
  margin-top: var(--v-offset-lt);
  perspective: 110vw;
  transform-style: preserve-3d;
  grid-template: 1fr / 1fr 1fr;
  justify-self: center;
  gap: 0 1vw;
  display: grid;
}

.gain-message__item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform-origin: bottom;
  transition: opacity .75s ease-out;
}

.gain-message__item:first-child {
  opacity: 0;
  animation: none;
}

body.first-spin-complete .gain-message__item:first-child {
  opacity: 1;
  animation: .75s forwards flip-in-top;
}

.gain-message__item:last-child {
  opacity: 0;
  animation: none;
}

body.second-spin-complete .gain-message__item:last-child {
  opacity: 1;
  animation: .75s forwards flip-in-top;
}

@keyframes flip-in-top {
  0% {
    opacity: 0;
    transform: perspective(110vw)rotateX(-90deg)translateY(70%);
  }

  100% {
    opacity: 1;
    transform: perspective(110vw)rotateX(0)translateY(0);
  }
}

@media (width <= 1199.98px) {
  .character-elements {
    pointer-events: none;
    justify-content: center;
    width: calc(100% + 260px);
    margin: auto;
    display: flex;
    position: absolute;
    inset: auto 0 0;
    overflow: hidden;
    transform: translate(-130px, 10vh);
  }
}

@media (width >= 1200px) {
  .character-elements {
    pointer-events: none;
    justify-content: center;
    width: min(96%, 1800px);
    height: fit-content;
    margin: auto;
    display: flex;
    position: absolute;
    inset: 0;
    transform: translateY(8vh);
  }

  .character-elements .image-container {
    filter: drop-shadow(0 0 1px #00000059);
    width: fit-content;
  }

  .character-elements .image-container--character-one {
    transform: translateX(-160px);
  }

  .character-elements .image-container--character-two {
    transform: translateX(160px);
  }
}

.select-option--signup-currency .select-option-trigger, .select-option--signup-currency .select-option-list-item {
  padding-left: 54px;
}

.select-option--signup-currency .select-option-trigger--rub, .select-option--signup-currency .select-option-list-item--rub {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwaDI0djZIMFYwWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PHBhdGggc3Ryb2tlPSIjRDBEMEQwIiBkPSJNLjUuNWgyM3YxN0guNVYuNVoiLz48cGF0aCBmaWxsPSIjMDAzOUE2IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDZoMjR2NkgwVjZaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48cGF0aCBmaWxsPSIjRDUyQjFFIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEyaDI0djZIMHYtNloiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
}

.select-option--signup-currency .select-option-trigger--kzt, .select-option--signup-currency .select-option-list-item--kzt {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAxQTVDOCIgZD0iTTAgMGgyNHYxOEgweiIvPjxwYXRoIHN0cm9rZT0iI0ZGRTcwMCIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBkPSJtOCAxMSA0LjUgM00xMS41IDE0bDQuNS0zIi8+PGNpcmNsZSBjeD0iMTIiIGN5PSI4IiByPSIzIiBmaWxsPSIjRkZFNzAwIi8+PC9zdmc+");
}

.select-option--signup-currency .select-option-trigger--azn, .select-option--signup-currency .select-option-list-item--azn {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzUwOUUyRiIgZD0iTTI0IDEySDB2NmgyNHYtNloiLz48cGF0aCBmaWxsPSIjRUYzMzQwIiBkPSJNMjQgNkgwdjZoMjRWNloiLz48cGF0aCBmaWxsPSIjMDBCNUUyIiBkPSJNMjQgMEgwdjZoMjRWMFoiLz48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTEuMSAxMS43Yy44ODMgMCAxLjY2OC0uNDI0IDIuMTYtMS4wOGEyLjI1IDIuMjUgMCAxIDEgMC0zLjI0MkEyLjcgMi43IDAgMSAwIDExLjEgMTEuN1oiLz48cGF0aCBmaWxsPSIjZmZmIiBkPSJtMTQuMzg1IDguMzA3LS4yODctLjgwNy0uMjg3LjgwNy0uNzczLS4zNjcuMzY3Ljc3My0uODA3LjI4Ny44MDcuMjg3LS4zNjcuNzc0Ljc3My0uMzY4LjI4Ny44MDcuMjg3LS44MDcuNzc0LjM2OC0uMzY4LS43NzQuODA3LS4yODctLjgwNy0uMjg3LjM2OC0uNzczLS43NzQuMzY3WiIvPjwvc3ZnPg==");
}

.select-option--signup-currency .select-option-trigger--try, .select-option--signup-currency .select-option-list-item--try {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI0UzMEExNyIgZD0iTTAgMGgyNHYxOEgwVjBaIi8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTEzLjI5NiA4LjQzOSAxMS42MjYgOWwxLjY3LjU2MXYxLjgxN2wxLjAzMy0xLjQ3TDE2IDEwLjQ3IDE0Ljk2NyA5IDE2IDcuNTNsLTEuNjcxLjU2Mi0xLjAzMy0xLjQ3VjguNDRaIi8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTkuNTUgMTMuNjc1YTQuODczIDQuODczIDAgMCAwIDIuMzczLTIuMDAxIDMuODQ3IDMuODQ3IDAgMCAxLTEuOTcxIDEuMjE1IDMuNzUgMy43NSAwIDAgMS0yLjI5MS0uMTU0IDMuODkzIDMuODkzIDAgMCAxLTEuODA0LTEuNDY5QTQuMDk1IDQuMDk1IDAgMCAxIDUuMTc3IDljMC0uODEuMjM3LTEuNi42OC0yLjI2NmEzLjg5MyAzLjg5MyAwIDAgMSAxLjgwNC0xLjQ2OSAzLjc1IDMuNzUgMCAwIDEgMi4yOS0uMTU0Yy43NjIuMTkgMS40NS42MTMgMS45NzIgMS4yMTVBNC44NzMgNC44NzMgMCAwIDAgOS41NSA0LjMyNGE0LjY4NyA0LjY4NyAwIDAgMC0zLjA2LS4xMjYgNC44MzggNC44MzggMCAwIDAtMi41MiAxLjc5OUMzLjM0IDYuODYzIDMgNy45MTcgMyA5YzAgMS4wODMuMzQgMi4xMzcuOTcgMy4wMDNhNC44MzggNC44MzggMCAwIDAgMi41MiAxLjggNC42ODggNC42ODggMCAwIDAgMy4wNi0uMTI4WiIvPjwvc3ZnPg==");
}

.select-option--signup-currency .select-option-trigger--mxn, .select-option--signup-currency .select-option-list-item--mxn {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAwNjg0NyIgZD0iTTAgMGg3djE4SDB6Ii8+PHBhdGggZmlsbD0iI0NFMTEyNiIgZD0iTTE3IDBoN3YxOGgtN3oiLz48cGF0aCBmaWxsPSIjRUVFIiBkPSJNNyAwaDEwdjE4SDd6Ii8+PHJlY3Qgd2lkdGg9IjQiIGhlaWdodD0iNSIgeD0iMTEiIHk9IjUiIGZpbGw9IiNDMTY5NEYiIHJ4PSIyIi8+PHBhdGggZmlsbD0iI0E2RDM4OCIgZD0iTTggMTBhNCA0IDAgMCAwIDggMGgtMWEzIDMgMCAwIDEtNiAwSDhaIi8+PHBhdGggZmlsbD0iIzU1QUNFRSIgZD0iTTEyIDExLjgzM2MxLjEwNSAwIDItLjI5OCAyLS42NjYgMC0uMzY4LS44OTUtLjY2Ny0yLS42NjdzLTIgLjI5OS0yIC42NjdjMCAuMzY4Ljg5NS42NjYgMiAuNjY2WiIvPjxwYXRoIGZpbGw9IiNGRkNDNEQiIGQ9Ik0xMi41IDExLjgzM2MuNTUyIDAgMS0uMjk4IDEtLjY2NiAwLS4zNjgtLjQ0OC0uNjY3LTEtLjY2N3MtMSAuMjk5LTEgLjY2N2MwIC4zNjguNDQ4LjY2NiAxIC42NjZaIi8+PHBhdGggZmlsbD0iIzVDOTEzQiIgZD0iTTkgN2gxdjJIOXoiLz48L3N2Zz4=");
}

.select-option--signup-currency .select-option-trigger--rub, .select-option--signup-currency .select-option-trigger--kzt, .select-option--signup-currency .select-option-trigger--azn, .select-option--signup-currency .select-option-trigger--try, .select-option--signup-currency .select-option-trigger--mxn, .select-option--signup-currency .select-option-list-item--rub, .select-option--signup-currency .select-option-list-item--kzt, .select-option--signup-currency .select-option-list-item--azn, .select-option--signup-currency .select-option-list-item--try, .select-option--signup-currency .select-option-list-item--mxn {
  background-position: 18px;
  background-repeat: no-repeat;
  background-size: 24px 18px;
}

.signup-terms {
  margin: -10px 0;
  padding: 0;
  display: flex;
}

.signup-terms label {
  margin-left: -10px;
  font-size: 13px;
}

.signup-terms label span:first-child {
  white-space: unset;
  text-overflow: unset;
  overflow: auto;
}

.signup-terms a:hover {
  text-decoration: none;
}

.signup-button {
  letter-spacing: .5px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  appearance: none;
  text-transform: uppercase;
  color: #fff;
  background-image: linear-gradient(#00b047, #008e39, #ff4910, #d83502);
  background-size: 100% 400%;
  border: none;
  border-radius: 7.5px;
  place-content: center;
  width: 100%;
  height: 48px;
  margin: 0;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  transition: all .225s;
  display: grid;
}

.signup-container {
  background-color: #fff;
  border-radius: 17.5px;
  padding: 15px;
}

@media (width >= 992px) {
  .signup-container {
    padding: 20px;
  }
}

.tickbox {
  --tickbox-checkbox-radius: 5px;
  --tickbox-color-active: #008e39;
}

.signup-form {
  gap: 15px 0;
  width: min(100%, 460px);
  margin: auto;
  display: grid;
  position: relative;
}

.signup-form input[type="password"]:focus {
  transition: background-color 600000s, color 600000s;
}

.signup-form input:-webkit-autofill {
  transition: background-color 600000s, color 600000s;
}

.signup-form input:-webkit-autofill:focus {
  transition: background-color 600000s, color 600000s;
}

.signup-form input[data-autocompleted] {
  background-color: #0000 !important;
}

.text-field-helper {
  display: none;
}

.input--error + .text-field-helper {
  display: block;
}

.dialog-lite__container-inner {
  background-color: #fff;
  border-radius: 12px;
  max-width: 460px;
}

.main-slogan {
  text-align: center;
  font-family: Oswald, serif;
  font-weight: 900;
  transition: transform .5s;
  transform: translateY(45%);
}

body.first-spin-complete .main-slogan {
  transform: translateY(0);
}

.main-slogan .display-medium {
  color: #00b147;
  margin: 0;
}

.main-slogan .display-small {
  color: #fff;
  margin: 0;
}

.footer-disclaimer {
  margin: var(--v-offset-lt) auto;
  text-align: center;
  color: #fff;
  width: min(100% - 40px, 1400px);
}
/*# sourceMappingURL=index.5e37a87d.css.map */
